@import "ui/src/styles/colors";
@import "ui/src/styles/fonts";

div.ant-radio-group {
  margin-left: 20px;

  .ant-radio-wrapper {
    padding: 5px;
    display: flex;
    white-space: break-spaces;

    span.ant-radio {
      padding-top: 4px;
    }
  }
}