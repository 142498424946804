.qwikwire {
  margin-top: 16px; }
  .qwikwire .transaction-breadcrumb {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row; }
    .qwikwire .transaction-breadcrumb .ant-breadcrumb-link {
      font-size: 20px;
      font-weight: 700; }
    .qwikwire .transaction-breadcrumb .ant-breadcrumb-link a {
      font-weight: 400; }
  .qwikwire .transaction-breadcrumb-icon {
    margin-right: 16px; }
