.panel-items {
  .ant-form-item {
    margin-bottom: 0;
  }

  .panel-item.-padded {
    padding: 4px;
  }

  .panel-item.-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  button.remove-icon {
    margin-top: 8px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0 4px;
  }
}

.add-button {
  margin-top: 1rem;
}

.submit-button {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  >*:first-child {
    margin-right: 8px;
  }
}

button.ant-btn.-link-button {
  text-decoration: underline;
  color: #1890ff;
  transition: color 200ms ease-in;
  border: 0;
  border: none;
}
