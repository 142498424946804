@import url(../../styles/card.css);
.ant.input.form-input {
  color: #505565; }

.ant-form-item-control.has-error input.ant-input {
  border-color: #f5222d !important; }

.ant-form-item-control .iti {
  width: 100%; }
  .ant-form-item-control .iti input {
    width: inherit;
    border: 0.8px;
    border-style: solid;
    border-radius: 4px; }

.ant-input-group-addon .ant-select-open .ant-select-selection .ant-select-selection__rendered,
.ant-input-group-addon .ant-select-focused .ant-select-selection .ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.65); }

.payment-link-card {
  margin-bottom: 8vh; }
  .payment-link-card .card-standard {
    min-height: 50px;
    height: auto; }

.details-body .ant-radio-group .ant-radio-wrapper {
  display: unset; }

.details-body span {
  font-weight: normal; }

.button-group {
  display: flex;
  justify-content: space-around;
  padding: 24px 32px; }
  .button-group .filter-button {
    width: 100%; }
    .button-group .filter-button:last-child {
      margin-left: 16px; }

.button-group {
  display: flex;
  justify-content: flex-end;
  padding: 24px 32px; }

.payment-link-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start; }
  .payment-link-header h2 {
    display: flex;
    color: #505565; }

.select-field .ant-select-selection {
  align-items: center;
  display: flex;
  min-height: 48px;
  padding: 0 5px; }
  .select-field .ant-select-selection span {
    color: #A1A4B1;
    padding-right: 10px; }

.icon-label {
  margin-left: 20px; }
