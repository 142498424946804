@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec.eot");
  src: url("../fonts/Sailec/Sailec.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec.woff2") format("woff2"), url("../fonts/Sailec/Sailec.woff") format("woff"), url("../fonts/Sailec/Sailec.ttf") format("truetype"); }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Light.eot");
  src: url("../fonts/Sailec/Sailec-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Light.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Light.woff") format("woff"), url("../fonts/Sailec/Sailec-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Bold.eot");
  src: url("../fonts/Sailec/Sailec-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Bold.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Bold.woff") format("woff"), url("../fonts/Sailec/Sailec-Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Light.eot");
  src: url("../fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Light.woff2") format("woff2"), url("../fonts/Lato/Lato-Light.woff") format("woff"), url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700; }

.panel.panel-standard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: #FFFFFF;
  font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; }
  .panel.panel-standard .ant-form-explain {
    margin-bottom: 8px; }
  .panel.panel-standard .panel-header {
    padding: 28px 32px;
    border-bottom: 1px solid #E1E7ED; }
  .panel.panel-standard .panel-body {
    padding: 28px 32px; }
  .panel.panel-standard .panel-footer {
    border-top: 1px solid #E1E7ED;
    padding: 28px 32px; }
    .panel.panel-standard .panel-footer.-space-between {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row; }
      .panel.panel-standard .panel-footer.-space-between > *:first-child {
        margin-right: auto; }
      .panel.panel-standard .panel-footer.-space-between > *:only-child {
        margin-right: 0; }
    .panel.panel-standard .panel-footer.-space-before {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row; }
    .panel.panel-standard .panel-footer.-flex-end {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row; }
      .panel.panel-standard .panel-footer.-flex-end > *:not(:last-child) {
        margin-right: 0.5em; }
  .panel.panel-standard .panel-body h3,
  .panel.panel-standard .panel-header h3 {
    margin-bottom: 4px;
    font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #505565;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.32; }
  .panel.panel-standard .panel-item-group {
    margin-top: 0;
    margin-bottom: 20px; }
  .panel.panel-standard .panel-item-group > h3 {
    margin-bottom: 12px; }
  .panel.panel-standard .panel-item {
    padding-bottom: 12px;
    color: #8B90A0; }
  .panel.panel-standard .panel-item.panel-item-end {
    display: flex;
    justify-content: flex-end; }
  .panel.panel-standard .panel-item:not(:first-child) {
    margin-top: 8px; }
  .panel.panel-standard .ant-col.panel-item {
    margin-top: 0; }
  .panel.panel-standard .panel-breakdown-item {
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    color: #8B90A0;
    border-bottom: 1px dashed #D3D4D8; }
  .panel.panel-standard .panel-breakdown-item div:last-child {
    text-align: right; }
  .panel.panel-standard .panel-inline-item:not(:first-child),
  .panel.panel-standard .panel-breakdown-item:not(:first-child) {
    margin-top: 8px; }
  .panel.panel-standard .panel-breakdown-item:last-child {
    border-bottom: none; }
  .panel.panel-standard .panel-item .panel-item-cellar {
    border: 1px solid #D3D4D8;
    border-radius: 3px;
    padding: 8px;
    background-color: #F9FCFF;
    width: 80%; }
  .panel.panel-standard .panel-item strong,
  .panel.panel-standard .panel-breakdown-item strong {
    color: #505565; }
  .panel.panel-standard .panel-badge {
    padding: 4px 12px;
    border-radius: 3px;
    background-color: #FFFFFF;
    color: #152935; }
  .panel.panel-standard .panel-badge .anticon {
    margin-right: 8px; }
  .panel.panel-standard .panel-badge-success {
    background-color: #96D647;
    color: #FFFFFF; }
  .panel.panel-standard .panel-badge-neutral {
    background-color: #399DE5;
    color: #FFFFFF; }

.panel.panel-warning {
  background: #FFE680; }
