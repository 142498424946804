.ant-table-wrapper.table-standard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px; }
  .ant-table-wrapper.table-standard .ant-table-thead {
    table-layout: fixed; }
    .ant-table-wrapper.table-standard .ant-table-thead tr {
      height: 48px; }
      .ant-table-wrapper.table-standard .ant-table-thead tr > th {
        background-color: #FFF;
        color: #505565;
        font-weight: 700;
        width: auto; }
  .ant-table-wrapper.table-standard .ant-table-body tr td {
    width: auto;
    height: 48px;
    padding: 8px 16px; }
  .ant-table-wrapper.table-standard .ant-table-pagination.mini {
    background-color: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 28px 0; }
    .ant-table-wrapper.table-standard .ant-table-pagination.mini li {
      margin-right: 15px;
      font-weight: 600; }
      .ant-table-wrapper.table-standard .ant-table-pagination.mini li a {
        color: #8B90A0;
        font-size: 14px; }
    .ant-table-wrapper.table-standard .ant-table-pagination.mini .ant-pagination-total-text {
      align-self: flex-start;
      color: #8B90A0;
      flex: 1;
      font-weight: normal;
      padding-left: 16px; }
    .ant-table-wrapper.table-standard .ant-table-pagination.mini .ant-pagination-item-active {
      background-color: #36D1DC;
      border: 0;
      border-radius: 2px; }
      .ant-table-wrapper.table-standard .ant-table-pagination.mini .ant-pagination-item-active a {
        color: #FFF; }
    .ant-table-wrapper.table-standard .ant-table-pagination.mini .ant-pagination-next {
      margin-right: 30px; }
