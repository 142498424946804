@import "./colors";
@import "./fonts";

.filter-modal {
  // Override antd classes for compactness
  font-family: $lato;

  .ant-modal-title {
    font-family: $sailec;
    font-weight: 700;
    font-size: 16px;
  }
  .ant-modal-body .ant-form.ant-form-horizontal {
    padding: 8px 24px;
  }
  .ant-form-item-control {
    line-height: 32px;
  }

  // Override antd's default color for checkboxes
  .ant-modal-content .ant-modal-body .ant-checkbox,
  .ant-modal-content .ant-modal-body .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $blue;
    border-color: $blue;
  }
  .ant-checkbox-checked::after {
    border: 1px solid $blue;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $blue;
  }
  .ant-form.ant-form-horizontal {
    .ant-form-field {
      margin-bottom: 8px;
    }
  }
  label.ant-checkbox-group-item {
    width: auto;
    font-size: 14px;
  }
  @media screen and (min-width: 768px) {
    label.ant-checkbox-group-item {
      width: 47.2%;
      padding-bottom: 4px;
    }
  }

  // Custom classes for the filter modal
  form {
    .label {
      font-weight: 700;
      font-size: 14px;
      font-family: $sailec;
    }
    .clear-link {
      cursor: pointer;
      color: $blue;
    }
  }
}
