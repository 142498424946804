.request-response-content {
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  >div {
    margin: 0 4px;
    width: clamp(40ch, 40vw, 60ch);
    height: 60ch;
    overflow: auto;

    >pre {
      counter-reset: line;
      max-height: 50ch;
      margin: 0;

      >span {
        .is-standard {
          color: rgba(0, 0, 0, 0.6);
        }

        .is-key {
          color: #DD1700;
        }

        .is-string {
          color: #6106FD;
        }

        .is-number {
          color: teal;
        }

        .is-bool {
          color: blue;
        }

        &::before {
          position: sticky;
          left: 0;
          background: #fff;
          text-align: right;

          display: inline-block;
          width: 4.5ch;
          margin-right: 1ch;
          padding-right: 1ch;
          border-right: 1px solid rgba(0, 0, 0, 0.4);

          counter-increment: line;
          content: counter(line);
          color: teal;
        }
      }
    }
  }
}
