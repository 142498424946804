.password-meter-feedback p {
  margin-bottom: 0;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.65); }
  .password-meter-feedback p.warning {
    color: red; }

.password-meter-feedback .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-1em - 8px);
  padding-right: calc(1em + 8px); }

.password-meter-feedback .ant-progress-text {
  width: 1em; }
