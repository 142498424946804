.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }
  .navigation .navigation-menu, .navigation .navigation-footer {
    text-align: left;
    border-right: 0; }
    .navigation .navigation-menu li, .navigation .navigation-footer li {
      padding: 0 8px 0 8px;
      font-size: 1em;
      height: auto; }
    .navigation .navigation-menu .ant-menu-item.ant-menu-item-selected, .navigation .navigation-footer .ant-menu-item.ant-menu-item-selected {
      border-left: 3px solid #36D1DC;
      color: #152935;
      font-weight: 700; }
  .navigation .ant-menu-vertical {
    border-right: 0; }
  .navigation .custom-icon {
    display: inline-block;
    width: 12.5px;
    height: 12.5px;
    margin-left: 18px;
    margin-right: 24px;
    vertical-align: top; }
  .navigation .custom-icon path {
    fill: #D3D4D8; }
  .navigation .ant-menu-item-selected .custom-icon path {
    fill: #36D1DC; }
  .navigation .logo-wrapper {
    padding: 5px 15px 15px;
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center; }
    .navigation .logo-wrapper a {
      display: block;
      width: 100%; }
  .navigation .merchant-dropdown-picker {
    margin: 0 0 12px 0;
    padding: 16px;
    text-align: left;
    cursor: pointer;
    font-size: 14px; }
    .navigation .merchant-dropdown-picker .dropdown-selector {
      display: flex;
      justify-content: space-between; }
    .navigation .merchant-dropdown-picker .dropdown-selector-text {
      display: flex;
      margin-right: 4px;
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .navigation .merchant-dropdown-picker .dropdown-selector-name {
      margin-left: 10px;
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.ant-dropdown.merchant-dropdown-picker {
  position: fixed;
  background-color: #F9FCFF;
  min-width: 320px;
  max-height: 240px;
  overflow-y: scroll;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }
  .ant-dropdown.merchant-dropdown-picker .ant-dropdown-menu-item {
    padding: 8px 12px; }
