@import url(../../styles/breadcrumb.css);
@import url(../../styles/table.css);
.breadcrumb-row {
  padding: 10px 0; }

.filter-section {
  color: #8B90A0;
  font-family: Lato; }

.dropdowns {
  display: flex;
  color: #8B90A0;
  margin-top: .5em;
  margin-bottom: 30px; }
  .dropdowns .dropdown-half {
    display: flex;
    flex: 3; }
    .dropdowns .dropdown-half:first-child {
      flex: 2; }
    .dropdowns .dropdown-half .dropdown-container {
      align-self: center;
      flex: 1; }
      .dropdowns .dropdown-half .dropdown-container:nth-child(2) {
        margin-left: 1rem; }
    .dropdowns .dropdown-half .single-dropdown {
      width: 100%; }
      .dropdowns .dropdown-half .single-dropdown .ant-select-selection {
        display: flex;
        align-items: center;
        background-color: #FFF;
        border: 1px solid #D3D4D8;
        min-height: 48px; }
        .dropdowns .dropdown-half .single-dropdown .ant-select-selection .dropdown-icon {
          padding-top: 1px; }
        .dropdowns .dropdown-half .single-dropdown .ant-select-selection:hover {
          cursor: pointer; }
    .dropdowns .dropdown-half .single-dropdown-default {
      color: #8B90A0; }
    .dropdowns .dropdown-half .switch-container {
      margin-left: 1rem;
      color: #505565; }
      .dropdowns .dropdown-half .switch-container span {
        margin-left: .5rem; }
    .dropdowns .dropdown-half .search-container {
      padding-left: .5rem; }
      .dropdowns .dropdown-half .search-container .ant-input {
        height: 36px; }

.table-standard tr > th {
  width: 30%; }
  .table-standard tr > th:first-child {
    width: 40%; }

.table-item-link.user-name {
  color: #505565;
  font-weight: normal; }

.scope-role {
  color: #8B90A0; }

.scope-role-dropdown {
  max-height: 300px;
  overflow-y: scroll; }
