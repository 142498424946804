@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec.eot");
  src: url("../fonts/Sailec/Sailec.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec.woff2") format("woff2"), url("../fonts/Sailec/Sailec.woff") format("woff"), url("../fonts/Sailec/Sailec.ttf") format("truetype"); }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Light.eot");
  src: url("../fonts/Sailec/Sailec-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Light.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Light.woff") format("woff"), url("../fonts/Sailec/Sailec-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Bold.eot");
  src: url("../fonts/Sailec/Sailec-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Bold.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Bold.woff") format("woff"), url("../fonts/Sailec/Sailec-Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Light.eot");
  src: url("../fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Light.woff2") format("woff2"), url("../fonts/Lato/Lato-Light.woff") format("woff"), url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700; }

.linechart {
  padding: 24px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }

.linechart text {
  color: #8C8C8C;
  font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12.2px;
  line-height: 1.2; }

.linechart .axis {
  stroke: #D3D4D8;
  stroke-width: 2;
  overflow-x: scroll; }

.linechart .line {
  fill: none;
  stroke: #36D1DC;
  stroke-width: 3; }

.linechart .line-tick {
  stroke: #D3D4D8; }

.linechart .tx-point circle {
  fill: #36D1DC;
  stroke: #FFFFFF; }

.linechart .tooltip {
  background-color: #F9FCFF;
  color: #152935;
  border-radius: 4px;
  padding: 12px 8px;
  position: absolute;
  min-width: 240px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }

.linechart .tooltip-header {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 4px; }
