.ant-row.ant-form-item.deactivate-section {
  padding: 26px 32px;
  border-top: 1px solid #E1E7ED;
  margin-bottom: 0; }
  .ant-row.ant-form-item.deactivate-section .deactivate-text {
    line-height: 24px; }
  .ant-row.ant-form-item.deactivate-section .title {
    color: #505565;
    font-weight: 700; }
  .ant-row.ant-form-item.deactivate-section .subtitle {
    color: #8B90A0; }
  .ant-row.ant-form-item.deactivate-section .button-standard {
    background-color: transparent;
    border: 1px solid #36D1DC;
    color: #36D1DC;
    margin-top: 24px; }
    .ant-row.ant-form-item.deactivate-section .button-standard:hover, .ant-row.ant-form-item.deactivate-section .button-standard:active {
      opacity: 0.8; }
  .ant-row.ant-form-item.deactivate-section .disabled {
    background-color: #FFFFFF;
    border: 1px solid #D3D4D8;
    color: #D3D4D8; }
