@import "../src/styles/colors";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Sailec", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-breadcrumb.ant-breadcrumb {
  .anticon {
    min-width: 14px;
  }
}

.ant-table-pagination.ant-pagination {
  float: none;
}

.ant-layout.ant-layout {
  background: #F9FCFF;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #36D1DC;
  border-right-width: 1px !important;
}
