h4 {
  display: flex;
  justify-content: space-between; }

.legends {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .legends .legend {
    display: flex;
    align-items: center;
    padding-right: 72px;
    margin-top: 5px; }
    .legends .legend .legend-color {
      height: 24px;
      width: 24px; }
    .legends .legend .legend-text {
      padding-left: 8px; }
