.logo {
  text-align: center;
  fill: #36D1DC;
  display: block; }

.logo-brand {
  max-width: 90%;
  max-height: 160px; }

.logo-title {
  margin: 2px auto 0;
  text-align: right;
  font-size: 12px;
  color: #232735; }
