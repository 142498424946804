.flags {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flag-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  >span {
    max-width: 40ch;
  }
}

.ant-select-selection.ant-select-selection--single {
  div{
    height: 30px;
  }
}