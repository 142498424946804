@import url(../../styles/card.css);
.ant.input.form-input {
  color: #505565; }

.dropdown-label {
  color: #505565;
  font-size: 16px;
  line-height: 27px;
  padding-bottom: 12px; }

.dropdown-role .role-select .ant-select-selection {
  align-items: center;
  display: flex;
  min-height: 48px;
  padding: 0 5px; }
  .dropdown-role .role-select .ant-select-selection span {
    color: #A1A4B1;
    padding-right: 10px; }

.dropdown-role .merchant-select .ant-select-selection {
  font-size: 14px;
  padding: 8px 8px 10px;
  line-height: 20px; }
  .dropdown-role .merchant-select .ant-select-selection .ant-select-selection__rendered {
    margin-left: 3px; }
    .dropdown-role .merchant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
      margin-left: 0; }
    .dropdown-role .merchant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__choice {
      background-color: #F9FCFF;
      border: 1px solid #E1E7ED;
      border-radius: 3px;
      font-size: 12px; }
  .dropdown-role .merchant-select .ant-select-selection .ant-select-search__field__wrap input {
    height: auto; }

.dropdown-role .merchant-select input {
  height: auto;
  line-height: 20px; }

.dropdown-role .role-description {
  color: #8B90A0;
  line-height: 24px;
  margin-top: 14px;
  min-height: 5rem; }

.button-container {
  display: inline-flex;
  justify-content: space-between;
  width: 100%; }
  .button-container .add-user-button {
    background-color: #36D1DC;
    border: 0;
    border-radius: 100px;
    font-weight: bold;
    min-height: 48px;
    width: 200px; }
    .button-container .add-user-button:hover {
      background-color: #51D8E1; }
    .button-container .add-user-button:active {
      background-color: #51D8E1;
      opacity: 0.8; }
    .button-container .add-user-button:focus {
      background-color: #51D8E1; }
