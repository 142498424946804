.webhook-task-status {
  color: #fff;
  width: min-content;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13.2px;
  line-height: 1em;

  &.-in-progress {
    background: orange;
  }

  &.-success {
    background: #96D647;
  }

  &.-failed {
    background: #EB3800;
  }

  &.-sample {
    margin-left: 8px;
    background: #6106FD;
  }
}

button.webhook-event {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: darken(#1890ff, 25);

  &:hover {
    cursor: pointer;
    color: #1890ff;
  }
}

.retry-webhook-event {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    cursor: pointer;
    color: #1890ff;
  }

  button {
    width: 100%;
    height: 100%;

    background: transparent;
    border: none;
  }
}
