@import url(../../styles/breadcrumb.css);
@import url(../../styles/table.css);
.breadcrumb-row {
  padding: 10px 0; }

.filter-section {
  color: #8B90A0;
  font-family: Lato; }

.dropdowns {
  display: flex;
  color: #8B90A0;
  margin-top: .5em;
  margin-bottom: 30px; }
  .dropdowns .dropdown-half {
    display: flex;
    flex: 3; }
    .dropdowns .dropdown-half:first-child {
      flex: 2; }
    .dropdowns .dropdown-half .dropdown-container {
      align-self: center;
      flex: 1; }
      .dropdowns .dropdown-half .dropdown-container:nth-child(2) {
        margin-left: 1rem; }
    .dropdowns .dropdown-half .single-dropdown {
      width: 100%; }
      .dropdowns .dropdown-half .single-dropdown .ant-select-selection {
        display: flex;
        align-items: center;
        background-color: #FFF;
        border: 1px solid #D3D4D8;
        min-height: 48px; }
        .dropdowns .dropdown-half .single-dropdown .ant-select-selection .dropdown-icon {
          padding-top: 1px; }
        .dropdowns .dropdown-half .single-dropdown .ant-select-selection:hover {
          cursor: pointer; }
    .dropdowns .dropdown-half .single-dropdown-default {
      color: #8B90A0; }
    .dropdowns .dropdown-half .switch-container {
      margin-left: 1rem;
      color: #505565; }
      .dropdowns .dropdown-half .switch-container span {
        margin-left: .5rem; }
    .dropdowns .dropdown-half .search-container {
      padding-left: .5rem; }
      .dropdowns .dropdown-half .search-container .ant-input {
        min-height: 48px; }

.table-standard tr > th {
  width: 30%; }
  .table-standard tr > th:first-child {
    width: 40%; }

.table-item-link.user-name {
  color: #505565;
  font-weight: normal; }

.scope-role {
  color: #8B90A0; }

.scope-role-dropdown {
  max-height: 300px;
  overflow-y: scroll; }

.payment-types {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px; }
  .payment-types .ant-card-head-title {
    height: 72px;
    padding: 24px 0;
    color: #505565;
    font-weight: 700;
    width: auto;
    font-family: Sailec, 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #505565;
    font-variant-numeric: lining-nums; }
  .payment-types .ant-card-body {
    overflow: auto;
    max-height: 520px; }
  .payment-types .payment-type-cards {
    height: 220px;
    background: #FAFAFA;
    padding: 40px 22px; }
    .payment-types .payment-type-cards .payment-type-count {
      font-family: Sailec, 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: center; }
    .payment-types .payment-type-cards .payment-type-name {
      font-family: Sailec, 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #8C8C8C; }

.submit-field {
  margin-top: 1rem;
  padding: 20px 24px;
  border-top: 1px solid #E1E7ED;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }

.portals-configured {
  text-transform: none;
  color: #fff; }
  .portals-configured:hover {
    text-transform: none;
    color: #fff; }

.merchant-info-fields .ant-form-item-label {
  line-height: 1.5;
  padding: 0 0 8px; }
  .merchant-info-fields .ant-form-item-label label::after {
    content: none; }

.merchant-info-fields .ant-form-item-required {
  font-size: 14px; }
  .merchant-info-fields .ant-form-item-required::before {
    content: none; }

.merchant-info-fields input,
.merchant-info-fields .ant-select-selection {
  height: 52px; }

.merchant-info-fields .ant-select-selection {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.ant-tabs-tabpane.ant-tabs-tabpane-active .action-container.create-project {
  display: grid;
  justify-content: flex-end; }
