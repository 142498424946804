@import url(~antd/dist/antd.css);
@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec.eot");
  src: url("../fonts/Sailec/Sailec.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec.woff2") format("woff2"), url("../fonts/Sailec/Sailec.woff") format("woff"), url("../fonts/Sailec/Sailec.ttf") format("truetype"); }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Light.eot");
  src: url("../fonts/Sailec/Sailec-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Light.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Light.woff") format("woff"), url("../fonts/Sailec/Sailec-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Bold.eot");
  src: url("../fonts/Sailec/Sailec-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Bold.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Bold.woff") format("woff"), url("../fonts/Sailec/Sailec-Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Light.eot");
  src: url("../fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Light.woff2") format("woff2"), url("../fonts/Lato/Lato-Light.woff") format("woff"), url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700; }

.app {
  padding: 0;
  margin: 0 auto; }
  .app h1 {
    margin-top: 10px; }
  .app .Toastify .Toastify__toast-container--top-center {
    top: 1em;
    left: 0;
    margin-left: 0;
    width: 100%; }
    .app .Toastify .Toastify__toast-container--top-center .Toastify__toast {
      margin-right: auto;
      margin-left: auto;
      width: 100%; }
    @media (min-width: 768px) {
      .app .Toastify .Toastify__toast-container--top-center .Toastify__toast {
        width: 40%; } }

.w-100 {
  width: 100% imp !important; }

.main {
  min-height: 100vh; }
  .main .sidebar {
    position: fixed;
    left: 0;
    height: 100vh;
    overflow: auto;
    border-right: 1px solid #E0E0E0; }
  .main table {
    background: #ffffff; }
  .main .main-layout {
    margin-left: 250px;
    min-height: 100vh; }
    .main .main-layout .main-layout-header {
      background: #FFFFFF;
      margin: 0;
      padding: 12px 30px 8px 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #e0e0e0; }
    .main .main-layout .header-search {
      margin-left: 12px;
      margin-right: 12%;
      width: 80%; }
      .main .main-layout .header-search input.ant-input {
        z-index: 8;
        border: 1px solid #E0E0E0;
        background-color: #FFFFFF;
        color: #212121;
        padding-left: 42px;
        margin-left: 0;
        width: 72%; }
      .main .main-layout .header-search input.ant-input::placeholder {
        color: #757575; }
      .main .main-layout .header-search .ant-input-suffix {
        z-index: 9;
        left: 12px;
        right: 0;
        width: 18px; }
        .main .main-layout .header-search .ant-input-suffix svg {
          width: 16px;
          height: 16px; }
          .main .main-layout .header-search .ant-input-suffix svg path {
            fill: #757575; }
    .main .main-layout .header-dropdown {
      padding: 12px 0; }
    .main .main-layout .header-dropdown-text {
      cursor: pointer;
      line-height: 1rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 120px;
      font-size: 1em; }
    .main .main-layout .ant-avatar {
      margin-right: 8px; }
    .main .main-layout .header-dropdown-primary {
      font-weight: 700; }
    .main .main-layout .header-dropdown-secondary {
      color: #8C8C8C; }
    .main .main-layout .anticon-down {
      margin-left: 6px; }
    .main .main-layout .main-layout-content {
      padding-left: 16px;
      padding-right: 16px; }

.header-dropdown-menu {
  min-width: 180px; }
  .header-dropdown-menu .ant-dropdown-menu-item {
    font-size: 1em; }
  .header-dropdown-menu .ant-dropdown-menu-item > .anticon:first-child {
    margin-right: 0; }
  .header-dropdown-menu .ant-dropdown-menu-item span {
    margin-left: 0.8em; }
  .header-dropdown-menu .ant-dropdown-menu-item a {
    color: rgba(0, 0, 0, 0.65); }

.spinner {
  text-align: center;
  margin-top: 35px; }
  .spinner .spinner-text {
    margin-left: 15px; }

.heading-primary {
  color: #505565;
  font-size: 18px;
  font-weight: 700;
  font-family: "Sailec", sans-serif; }

.heading-primary.subheading {
  font-size: 14px; }

.text-primary {
  color: #505565;
  font-size: 14px;
  font-family: "Lato", sans-serif; }

.text-secondary {
  color: #8B90A0;
  font-size: 14px;
  font-family: "Lato", sans-serif; }

.text-pre-line {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #8B90A0;
  white-space: pre-line; }

.comments {
  white-space: pre-wrap; }

.button {
  font-size: 1em;
  min-height: 48px;
  min-width: 150px;
  border-radius: 2em;
  font-weight: 700;
  font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 12px 20px;
  height: 32px; }

.button.button-small {
  min-height: 32px;
  min-width: 0;
  height: 32px;
  padding: 8px 16px;
  line-height: 1.2; }

.button-standard,
a.button-standard {
  background-color: #36D1DC;
  border-color: #36D1DC;
  color: #FFFFFF;
  text-shadow: none; }
  .button-standard a,
  a.button-standard a {
    color: #FFFFFF; }
  .button-standard:hover,
  a.button-standard:hover {
    background-color: #51D8E1;
    border-color: transparent;
    color: #FFFFFF; }
  .button-standard:active,
  a.button-standard:active {
    background-color: #51D8E1;
    border-color: transparent;
    opacity: 0.8;
    color: #FFFFFF; }
  .button-standard:focus,
  a.button-standard:focus {
    background-color: #51D8E1;
    border-color: transparent;
    color: #FFFFFF; }

.button-standard-outline,
a.button-standard-outline {
  background-color: #FFFFFF;
  border-color: #36D1DC;
  color: #36D1DC; }
  .button-standard-outline a,
  a.button-standard-outline a {
    color: #36D1DC; }
  .button-standard-outline:hover,
  a.button-standard-outline:hover {
    background-color: #36D1DC;
    border-color: #36D1DC;
    color: #FFFFFF; }
  .button-standard-outline:focus,
  a.button-standard-outline:focus {
    background-color: #36D1DC;
    border-color: #36D1DC;
    color: #FFFFFF; }

.button-danger,
a.button-danger {
  background-color: #EB3800;
  border-color: #EB3800;
  color: #FFFFFF;
  text-shadow: none; }
  .button-danger a,
  a.button-danger a {
    color: #FFFFFF; }
  .button-danger:hover,
  a.button-danger:hover {
    background-color: #EB3800;
    border-color: transparent;
    color: #FFFFFF; }
  .button-danger:active,
  a.button-danger:active {
    background-color: #EB3800;
    border-color: transparent;
    opacity: 0.8;
    color: #FFFFFF; }
  .button-danger:focus,
  a.button-danger:focus {
    background-color: #EB3800;
    border-color: transparent;
    color: #FFFFFF; }

.button-danger-outline,
a.button-danger-outline {
  background-color: #FFFFFF;
  border-color: #EB3800;
  color: #EB3800; }
  .button-danger-outline a,
  a.button-danger-outline a {
    color: #EB3800; }
  .button-danger-outline:hover,
  a.button-danger-outline:hover {
    background-color: #EB3800;
    border-color: #EB3800;
    color: #FFFFFF; }
  .button-danger-outline:focus,
  a.button-danger-outline:focus {
    background-color: #EB3800;
    border-color: #EB3800;
    color: #FFFFFF; }

.button-standard-outline.button-fullwidth,
.button-standard.button-fullwidth,
.button-danger.button-fullwidth {
  width: 100% !important; }
