.file-icon-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  max-width: 250px; }
  .file-icon-wrapper .delete-button {
    background: none;
    border: none;
    margin-right: 1rem;
    cursor: pointer; }
  .file-icon-wrapper.is-removing {
    animation: opacity-change 2s infinite linear; }

.file-preview {
  margin: 24px 0; }
  .file-preview .pdfobject-container {
    height: 460px; }
  @media only screen and (min-width: 992px) {
    .file-preview > * {
      padding-right: 8ch; } }
