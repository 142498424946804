.card-standard.card-container {
  margin-bottom: 24px; }

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size: 12px; }

.header {
  padding: 40px 32px 32px; }
  .header .heading-primary {
    line-height: 31px;
    padding-bottom: .5rem; }
  .header .header-steps {
    font-family: Sailec, sans-serif; }
    .header .header-steps .ant-steps-item-process .ant-steps-item-tail:after {
      background-color: #E1E7ED;
      height: 2px; }
    .header .header-steps .ant-steps-item-finish .ant-steps-item-tail:after {
      background-color: #36D1DC;
      height: 2px; }

.table-container {
  border-top: 1px solid #E1E7ED; }
  .table-container .ant-row {
    margin: 0 !important; }
  .table-container .ant-table-wrapper.table-standard {
    box-shadow: none;
    margin-bottom: 0; }
    .table-container .ant-table-wrapper.table-standard .ant-table-pagination.mini {
      padding: 32px 16px; }
  .table-container .filters {
    padding: 0 28px 32px; }
  .table-container h4 {
    padding: 32px 32px 0; }

.settlement-field {
  margin: 0 0 24px 0; }
  .settlement-field .field-label {
    margin-bottom: 4px; }
  .settlement-field .field-content .field-content-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .settlement-field .field-content .field-content-tags .payments-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(54, 209, 220, 0.15);
      background: rgba(54, 209, 220, 0.1);
      font-weight: 500;
      min-height: 32px;
      max-width: 170px;
      min-width: 148px;
      margin: 0 3px 3px; }
      .settlement-field .field-content .field-content-tags .payments-tag a {
        font-weight: 700; }
    .settlement-field .field-content .field-content-tags .payments-tag:hover {
      background-color: rgba(81, 216, 225, 0.8);
      outline: none; }
  .settlement-field .field-content .field-content-textarea {
    border: 1px solid #D3D4D8;
    border-radius: 4px;
    box-shadow: none;
    color: #505565;
    font-size: 14px;
    padding: 16px;
    resize: none;
    width: 80%; }
    .settlement-field .field-content .field-content-textarea:focus {
      border: 1px solid rgba(81, 216, 225, 0.8);
      outline: none; }
  .settlement-field .field-content .field-content-datepicker {
    border: none;
    padding: 0; }
    .settlement-field .field-content .field-content-datepicker input {
      border: 1px solid #D3D4D8;
      box-shadow: none;
      height: 48px; }
      .settlement-field .field-content .field-content-datepicker input:focus {
        border: 1px solid rgba(81, 216, 225, 0.8);
        outline: none; }
      .settlement-field .field-content .field-content-datepicker input:hover {
        border: 1px solid rgba(81, 216, 225, 0.8);
        outline: none; }
      .settlement-field .field-content .field-content-datepicker input:active {
        border: 1px solid rgba(81, 216, 225, 0.8);
        outline: none; }

.middle {
  border-top: 1px solid #E1E7ED;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  min-height: 28rem; }
  .middle .middle-col {
    flex-basis: 100%; }
    .middle .middle-col:last-child {
      margin-left: 1rem; }

.footer {
  border-top: 1px solid #E1E7ED; }
  .footer .action-button {
    text-align: right; }
    .footer .action-button .next-button {
      margin-left: 24px; }
