@import "./colors";
@import "./fonts";

.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  .card-body {
    padding: 24px;
    background: $white;
  }
  .card-title {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    span {
      margin-left: 12px;
      margin-top: 4px;
      font-size: 1.12em;
    }
  }
  .card-footer {
    background: $background;
    padding: 8px 24px;
  }
  .card-footer.centered {
    text-align: center;
  }
}
.card-link {
  cursor: pointer;
  &:hover, &:focus {
    background-color: $background;
    h4 {
      color: $black200;
    }
  }
}

.breadcrumb-container {
  height: 90px;
  padding: 20px 0;
  .breadcrumb-item {
    cursor: pointer;
    display: inline;
  }
  .breadcrumb-icon {
    margin-right: 5px;
  }
  .breadcrumb-custom-icon {
    display: inline-block;
    width: 30.5px;
    height: 30.5px;
    margin-right: 28px;
    vertical-align: top;
  }

  display: flex;
  align-items: center;
  .ant-breadcrumb {
    flex: 1;
  }
  .add-button {
    flex: 0;
  }
}

.filters {
  .ant-input {
    height: 36px;
  }

  &.-flex-override {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    >* {
      width: auto;
    }
  }

  &.-flex-spaced {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &::before, &::after {
      content: none;
    }

    .search-items {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.transaction-filter {
  width: 100%;

  // .ant-calendar-range-picker-separator {
  //   padding-top: 8px;
  // }
  .ant-select-selection__placeholder {
    color: #8B90A0;
    height: 28px;
  }
  &.ant-select-auto-complete {
    .ant-select-selection__placeholder {
      margin-top: -2px;
    }
    .ant-select-selection__clear {
      right: 15px;
    }
    .anticon-close-circle > svg {
      width: 1.5em;
      height: 1.5em;
      margin-top: 5px;
      margin-right: 3px;
    }
    .anticon-close-circle > svg > path {
      fill: #FFA39E;
    }
  }

  .ant-select-search {
    &.ant-select-search--inline {
      background-color: #FFF;
      padding: 4px 0;
      height: 26px;
    }
  }
  input{
    &.ant-input {
      &.ant-select-search__field {
        height: 36px;
      }
    }
    &.ant-input {
      height: 36px;
    }
  }
  .ant-select-selection--single {
    height: 36px;
    .ant-select-selection__rendered {
      margin-top: 2px;
      .ant-select-selection__placeholder {
        height: 18px;
      }
      .ant-select-search__field__wrap {
        height: 24px;
      }
      .ant-select-selection-selected-value {
        height: 24px;
        margin-top: 4px;
      }
    }
  }
  .ant-select-selection--multiple {
    height: 36px;
    overflow-y: scroll;
  }
  .ant-select-selection {
    .ant-select-selection__rendered {
      line-height: 24px;
    }
  }
  .ant-calendar-picker {
    .ant-input {
      height: 36px;
    }
  }
}

.transaction-filter-merchant {
  .ant-calendar-picker-input.ant-input {
    height: 48px;
    padding: 0;
    line-height: 2.25;
    margin: 8px 0px;
    padding: 0px 30px;
  }

  .ant-calendar-range-picker-separator {
    padding-top: 8px;
  }
  .ant-select-selection__placeholder {
    color: #8B90A0;
  }
  &.ant-select-auto-complete {
    .ant-select-selection__placeholder {
      margin-top: -2px;
    }
    .ant-select-selection__clear {
      right: 15px;
    }
    .anticon-close-circle > svg {
      width: 1.5em;
      height: 1.5em;
      margin-top: 5px;
      margin-right: 3px;
    }
    .anticon-close-circle > svg > path {
      fill: #FFA39E;
    }
  }

  .ant-select-search {
    &.ant-select-search--inline {
      background-color: #FFF;
      padding: 4px 0;
      height: 32px;
    }
  }

  input {
    &.ant-input {
      &.ant-select-search__field {
        height: 48px;
      }
    }
  }
  .ant-select-selection--single {
    height: 48px;
    .ant-select-selection__rendered {
      margin-top: 8px;
    }
  }
  .ant-select-selection--multiple {
    height: 48px;
    overflow-y: scroll;
  }
  .ant-select-selection {
    .ant-select-selection__rendered {
      line-height: 28px;
    }
  }
}


.ant-menu {
  &.payment-table-filter {
    background-color: #f9fcff;
  }
}
.ant-menu-horizontal {
  .ant-menu-item:hover {
    &.payment-table-filter-item {
      background-color: #f9fcff;
      color: $blue;
      border-bottom: 2px solid $blue;
    }
    &.enrollment-table-filter-item {
      background-color: #f9fcff;
      color: $blue;
      border-bottom: 2px solid $blue;
    }
  }
  .ant-menu-item {
    &.payment-table-filter-item {
      width: 33.3333333%;
      font-family: Sailec;
      font-weight: 700;
      text-align: center;
      color: #d3d4d8;
      background-color: #f9fcff;
      border-bottom: 1px solid #d3d4d8;
    }
    &.enrollment-table-filter-item {
      width: 16.666666%;
      font-family: Sailec;
      font-weight: 700;
      text-align: center;
      color: #d3d4d8;
      background-color: #f9fcff;
      border-bottom: 1px solid #d3d4d8;
    }
    &.ant-menu-item-selected {
      background-color: #f9fcff;
      color: $blue;
      border-bottom: 2px solid $blue;
    }
  }
}

.enrollments-icon {
  fill: #36d1dc;
}

.ant-table-wrapper.enrollments-table {
  .ant-table-thead tr > th {
    width: 20%;

    &:nth-child(4) {
      width: 16%;
    }
  }
}

.file-icon-wrapper {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  max-width: 250px;

  .file-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    height: 48px;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    span{
      color: $blue;
    }
  }

  .delete-button {
    background: none;
    border: none;
    margin-right: 1rem;
    cursor: pointer;
  }

  &.is-removing {
    animation: opacity-change 2s infinite linear;
  }
}

.ant-table-wrapper.settlements-table {
  .file-icon-wrapper .file-icon svg {
    width: 24px;
    margin-right: 8px;
  }
  .file-icon-wrapper a {
    color: $blue;
    &.hover { color: $blue-focus; }
    &.focus { color: $blue-focus; }
    &.active { color: $blue-active; }
  }
}
.view-settlement-header {
  .file-icon-wrapper .file-icon svg {
    width: 16px;
  }
  .file-icon-wrapper a {
    color: $blue;
    &.hover { color: $blue-focus; }
    &.focus { color: $blue-focus; }
    &.active { color: $blue-active; }
  }
}
.view-settlement-attachment {
  .file-icon-wrapper {
    display: none;
  }
  .file-preview {
    margin-top: 0;
    margin-bottom: 0;
  }
}

table {
  .ant-table-column-title {
    font: 14px $heading-font;
    font-weight: 700;
  }

  .ant-pagination-total-text {
    font-size: 12px;
  }

  td {
    font: 13.4px $text-font;
    height: 72px;
  }
  td .text-primary,
  td .text-secondary {
    font: 13.4px $text-font;
  }
  tr.row-clickable {
    cursor: pointer !important;
  }

  th {
    height: 72px;
  }
  .table-col-item {
    display: flex;
    justify-content: flex-start;

    .icon-label {
      font: 14px $lato;
      color: $grey100;
      margin-left: 8px;
    }
  }

  .table-col-item-img {
    margin-top: 5px;
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }
  .table-col-item-img img {
    border: 1px solid $border-color;
    border-radius: 100%;
  }

  .table-item-link {
    color: $black200;
    display: flex;
    align-items: center;
    &:hover {
      color: $grey100;
    }
    &:active {
      color: $blue;
    }

    .anticon {
      margin-left: 2px;
    }
  }
}

.ant-modal.filter-modal {
  font-family: Sailec;

  .ant-modal-title {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    padding: 0 160px;
    color: #505565;
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 0;
    font-size: 14px;

    .checkbox-group {
      border-bottom: 1px solid $border-black;
      padding: 16px 32px 0;

      .filter-modal-checkbox {
        font-family: Lato;
        font-size: 14px;
        color: $grey100;
        margin-bottom: 16px;

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $blue;
          border-color: $blue;
        }
      }
    }

    .dropdown-group {
      padding: 16px 32px 0;

      .filter-modal-dropdown-select {
        width: 100%;
        margin-bottom: none;
      }
    }

    .date-group {
      padding: 0 32px;
      padding-bottom: 24px;
      border-bottom: 1px solid $border-black;
    }

    .button-group {
      display: flex;
      justify-content: space-around;
      padding: 24px 32px;

      .filter-button, .payment-link-modal-button {
        width: 100%;

        &:last-child {
          margin-left: 16px;
        }
      }
    }

    .filter-modal-body {
      margin-bottom: 16px;
      font-weight: 700;
    }

    .filter-modal-dropdown {
      margin-bottom: 8px;
      font-weight: 700;
    }
  }
}

.ant-modal.payment-link-modal .ant-modal-content {
  .status-message {
    text-align: center;
    padding: 5px;
    color: #595959;
  }

  .status-message.success {
    background-color: #F2FAE9;
  }

  .status-message.error {
    background-color: #FDE7E0;
  }

  .ant-modal-body .inner-body {
    padding: 16px 24px;
  }

  .button-group {
    border-top: 1px solid #E8E8E8;
    padding: 0;
  }
}