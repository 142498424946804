.merchant-dropdown-icon {
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .merchant-dropdown-icon:hover {
    color: #1890ff; }

.merchant-search-box {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #d9d9d9;
  background: #fff;
  z-index: 10; }
  .merchant-search-box input {
    width: 100%; }

.merchant-search-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .merchant-search-item .ant-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 16px; }
    .merchant-search-item .ant-avatar img {
      object-fit: contain; }
