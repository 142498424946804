@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec.eot");
  src: url("../fonts/Sailec/Sailec.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec.woff2") format("woff2"), url("../fonts/Sailec/Sailec.woff") format("woff"), url("../fonts/Sailec/Sailec.ttf") format("truetype"); }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Light.eot");
  src: url("../fonts/Sailec/Sailec-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Light.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Light.woff") format("woff"), url("../fonts/Sailec/Sailec-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Bold.eot");
  src: url("../fonts/Sailec/Sailec-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Bold.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Bold.woff") format("woff"), url("../fonts/Sailec/Sailec-Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Light.eot");
  src: url("../fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Light.woff2") format("woff2"), url("../fonts/Lato/Lato-Light.woff") format("woff"), url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700; }

.dialog-modal .status-message {
  text-align: center;
  padding: 5px;
  color: #595959; }

.dialog-modal .status-message.success {
  background-color: #F2FAE9; }

.dialog-modal .status-message.error {
  background-color: #FDE7E0; }

.dialog-modal .ant-btn {
  font-size: 14px;
  font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 8px 12px;
  height: 36px;
  border-radius: 32px;
  background-color: #FFFFFF;
  border-color: #36D1DC;
  color: #36D1DC;
  text-shadow: none; }
  .dialog-modal .ant-btn:hover {
    border-color: #51D8E1;
    color: #51D8E1; }
  .dialog-modal .ant-btn:active {
    border-color: rgba(81, 216, 225, 0.8);
    color: rgba(81, 216, 225, 0.8);
    opacity: 0.8; }
  .dialog-modal .ant-btn:focus {
    border-color: #51D8E1;
    color: #51D8E1; }

.dialog-modal .ant-btn-primary,
.dialog-modal a.ant-btn-primary {
  background-color: #36D1DC;
  border-color: #36D1DC;
  color: #FFFFFF;
  text-shadow: none; }
  .dialog-modal .ant-btn-primary a,
  .dialog-modal a.ant-btn-primary a {
    color: #FFFFFF; }
  .dialog-modal .ant-btn-primary:hover,
  .dialog-modal a.ant-btn-primary:hover {
    background-color: #51D8E1;
    border-color: transparent;
    color: #FFFFFF; }
  .dialog-modal .ant-btn-primary:active,
  .dialog-modal a.ant-btn-primary:active {
    background-color: #51D8E1;
    border-color: transparent;
    opacity: 0.8;
    color: #FFFFFF; }
  .dialog-modal .ant-btn-primary:focus,
  .dialog-modal a.ant-btn-primary:focus {
    background-color: #51D8E1;
    border-color: transparent;
    color: #FFFFFF; }

.dialog-modal .ant-btn-danger {
  background-color: #EB3800;
  border-color: #EB3800;
  color: #FFFFFF; }
  .dialog-modal .ant-btn-danger a {
    color: #FFFFFF; }
  .dialog-modal .ant-btn-danger:hover {
    background-color: #EB3800;
    border-color: transparent;
    color: #FFFFFF; }
  .dialog-modal .ant-btn-danger:active {
    background-color: #EB3800;
    border-color: transparent;
    opacity: 0.8;
    color: #FFFFFF; }
  .dialog-modal .ant-btn-danger:focus {
    background-color: #EB3800;
    border-color: transparent;
    color: #FFFFFF; }

.dialog-modal .ant-modal-body {
  padding: 16px 24px; }

.dialog-modal .ant-modal-title {
  font-weight: 700; }

.dialog-modal h3 {
  font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #505565;
  margin-bottom: 12px; }

.dialog-modal p {
  font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  min-width: 368px;
  max-height: 64px;
  margin: 0 auto;
  line-height: 24px;
  color: #152935;
  margin-bottom: 32px; }

.dialog-modal .ant-form-item {
  padding-bottom: 12px; }
  .dialog-modal .ant-form-item .ant-form-item-label {
    line-height: 24px;
    padding: 0; }
    .dialog-modal .ant-form-item .ant-form-item-label .ant-form-item-required::before,
    .dialog-modal .ant-form-item .ant-form-item-label .ant-form-item-required::after,
    .dialog-modal .ant-form-item .ant-form-item-label > label::after {
      content: '';
      display: none; }
    .dialog-modal .ant-form-item .ant-form-item-label label {
      font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-size: 14px;
      color: #505565;
      font-weight: 700; }
  .dialog-modal .ant-form-item .ant-select-selection,
  .dialog-modal .ant-form-item input {
    font-size: 14px;
    font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; }
  .dialog-modal .ant-form-item input {
    padding: 16px 8px;
    height: 32px; }
  .dialog-modal .ant-form-item .ant-select-selection {
    padding: 0 8px;
    height: 34px; }
    .dialog-modal .ant-form-item .ant-select-selection .ant-select-selection__rendered {
      margin-top: 0px;
      margin-left: 4px; }
  .dialog-modal .ant-form-item .ant-input-affix-wrapper input {
    padding-left: 52px;
    padding-right: 12px;
    width: 100%;
    height: 38px; }

.dialog-modal form .ant-row {
  margin: 0; }
  .dialog-modal form .ant-row textarea.ant-input {
    min-height: 120px; }

.dialog-modal .information-modal .text-title {
  font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px; }

.dialog-modal .information-modal .text-description {
  font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px; }
