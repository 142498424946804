@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec.eot");
  src: url("../fonts/Sailec/Sailec.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec.woff2") format("woff2"), url("../fonts/Sailec/Sailec.woff") format("woff"), url("../fonts/Sailec/Sailec.ttf") format("truetype"); }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Light.eot");
  src: url("../fonts/Sailec/Sailec-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Light.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Light.woff") format("woff"), url("../fonts/Sailec/Sailec-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Bold.eot");
  src: url("../fonts/Sailec/Sailec-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Bold.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Bold.woff") format("woff"), url("../fonts/Sailec/Sailec-Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Light.eot");
  src: url("../fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Light.woff2") format("woff2"), url("../fonts/Lato/Lato-Light.woff") format("woff"), url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700; }

.card-box-header {
  margin-bottom: 0; }

.card-box-header-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.card-list-item {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px; }

.header-row h3 .card-box-header {
  font-family: Sailec, 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #505565;
  font-variant-numeric: lining-nums; }

.header-row .transaction-badge {
  height: 24px;
  max-width: 120px;
  border-radius: 3px;
  color: white;
  line-height: 24px;
  font-family: Lato, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }

.header-row .card-list-item-subheader {
  color: #8B90A0;
  font-size: 12px;
  font-family: Sailec, 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }

.card-holder {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%; }
  .card-holder .ant-card-head {
    min-height: 83px;
    display: flex; }
    .card-holder .ant-card-head .ant-card-head-title {
      color: #505565;
      font-size: 14px;
      font-family: Sailec, 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 700; }
  .card-holder .text-secondary {
    line-height: 32px; }
  .card-holder .ant-card-body div {
    height: 33.33%; }
  .card-holder .card-details {
    height: 33%; }

.settlement-details .settlement-details-icon {
  padding: 10px;
  text-align: center; }

.settlement-details .settlement-details-text {
  color: #8B90A0;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: italic;
  padding: 10px;
  text-align: center; }

.settlement-details .settlement-details-button {
  padding: 16px 0; }

.settlement-details .settlement-details-link > a,
.settlement-details .settlement-details-file span {
  color: #36D1DC; }
