.accounts-header {
  height: 90px;
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px; }
  .accounts-header .accounts-header-title {
    font-size: 20px;
    font-weight: 700;
    margin-left: 20px; }

.accounts-content {
  margin-bottom: 35px; }
  .accounts-content .accounts-content-item {
    width: 50%;
    margin-right: 2rem; }
    .accounts-content .accounts-content-item:last-child {
      margin-right: 0; }

.ant-card.settings-card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px; }
  .ant-card.settings-card .ant-form-item-required::before {
    display: none; }
  .ant-card.settings-card .ant-card-head-wrapper {
    padding: 12px 0px 12px 0px; }
    .ant-card.settings-card .ant-card-head-wrapper .ant-card-head-title {
      color: #505565;
      font-size: 16px;
      font-weight: 700; }
  .ant-card.settings-card .ant-card-body {
    padding: 0; }
  .ant-card.settings-card .settings-card-body {
    padding: 24px;
    min-height: 360px; }
    .ant-card.settings-card .settings-card-body input {
      border-color: #D3D4D8;
      height: 52px; }
  .ant-card.settings-card .settings-card-action {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 24px;
    margin: 0;
    border-top: 1px solid #E1E7ED; }
