.info-item-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.info-item-hoverable {
  margin-left: 4px;

  &::after {
    position: absolute;

    opacity: 0;
    content: var(--breakdown-item-info);

    border: 1px solid #ddd;
    border-radius: 8px;

    width: 0;
    height: 0;
    text-align: left;

    background-color: #f7f9fc;
    color: #222;
    padding: 8px;
    pointer-events: none;
  }

  &:hover::after {
    pointer-events: initial;
    opacity: 1;
    width: max-content;
    height: max-content;

    max-width: 24ch;

    transition: opacity ease-in 200ms;
    -webkit-transition: opacity ease-in 200ms;
  }
}
