.ant-card.card-standard {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  min-height: 100vh; }
  .ant-card.card-standard .ant-form-item-required::before {
    display: none; }
  .ant-card.card-standard .ant-card-head-wrapper {
    padding: 12px 0px 12px 0px; }
    .ant-card.card-standard .ant-card-head-wrapper .ant-card-head-title {
      color: #505565;
      font-size: 16px;
      font-weight: 700; }
  .ant-card.card-standard .ant-card-body {
    padding: 0; }
  .ant-card.card-standard .details-body {
    padding: 32px; }
    .ant-card.card-standard .details-body label {
      font-size: 12px;
      font-weight: bold; }
    .ant-card.card-standard .details-body input {
      border-color: #D3D4D8;
      height: 48px; }
