@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec.eot");
  src: url("../fonts/Sailec/Sailec.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec.woff2") format("woff2"), url("../fonts/Sailec/Sailec.woff") format("woff"), url("../fonts/Sailec/Sailec.ttf") format("truetype"); }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Light.eot");
  src: url("../fonts/Sailec/Sailec-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Light.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Light.woff") format("woff"), url("../fonts/Sailec/Sailec-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Sailec";
  src: url("../fonts/Sailec/Sailec-Bold.eot");
  src: url("../fonts/Sailec/Sailec-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Sailec/Sailec-Bold.woff2") format("woff2"), url("../fonts/Sailec/Sailec-Bold.woff") format("woff"), url("../fonts/Sailec/Sailec-Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: url("../fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Light.eot");
  src: url("../fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Light.woff2") format("woff2"), url("../fonts/Lato/Lato-Light.woff") format("woff"), url("../fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: url("../fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700; }

.single-form {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }
  .single-form .single-form-card {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px 3px 0 0;
    padding: 2.6em 1.7em 1.6em 1.7em; }
  .single-form .single-form-card:only-child {
    border-radius: 3px; }
  .single-form .single-form-card-logo {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 36px; }
  .single-form .single-form-card-logo-icon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 44%; }
  .single-form .single-form-card-title {
    margin-bottom: 24px; }
  .single-form .single-form-card-title h1,
  .single-form .single-form-card-logo h1,
  .single-form .single-form-card-logo h2,
  .single-form .single-form-card-logo h3,
  .single-form .single-form-card-logo h4 {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.24;
    font-style: normal;
    color: #8B90A0;
    font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; }
  .single-form .single-form-card-title h1,
  .single-form .single-form-card-title h2,
  .single-form .single-form-card-title h3,
  .single-form .single-form-card-title h4 {
    font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.12em;
    font-weight: 700;
    line-height: 24px;
    color: #505565; }
  .single-form .single-form-card-title p {
    font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.24;
    color: #8B90A0; }
  .single-form .single-form-footer-action {
    display: block;
    padding: 2em;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid #E1E7ED;
    background-color: #F9FCFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 3px 3px; }
  .single-form .single-form-footer-action-text {
    text-align: center;
    font-family: Sailec, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1em;
    color: #36D1DC; }
  .single-form a.single-form-card-link {
    font-family: Lato, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.24;
    color: #8B90A0; }
  .single-form .ant-form-item {
    margin-bottom: 20px; }
  .single-form input.ant-input {
    border: 1px solid #D3D4D8;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    width: 100%; }
    .single-form input.ant-input:hover {
      border: 1px solid #36D1DC; }
    .single-form input.ant-input:focus {
      border: 1px solid #36D1DC; }

@media (min-width: 576px) {
  .single-form {
    padding-top: 12.5vh;
    padding-left: 0;
    padding-right: 0;
    width: 392px; } }
