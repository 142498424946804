.banner-container .banner {
  position: fixed;
  background-color: #FFCC00;
  z-index: 999;
  height: 5px;
  width: 100%; }

.banner-container .banner-text {
  height: 18px;
  width: 8%;
  left: 46%;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  color: white; }
