.global-alert {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .global-alert div {
    padding: 0 15px;
    color: #FFFFFF; }
    .global-alert div .icon-container {
      margin-top: 5px;
      padding: 0 5px; }
    .global-alert div .closeable {
      background: none;
      border: none;
      cursor: pointer;
      margin-top: 0; }
