.dropzone {
  align-items: center;
  display: flex;
  border-width: 2px;
  border-radius: 2px;
  border-color: #E0E0E0;
  border-style: dashed;
  background-color: #fafafa;
  text-align: center;
  padding: 20px;
  outline-color: #36D1DC;
  min-height: 280px;
  justify-content: center; }
  .dropzone.has-files {
    border-color: #36D1DC;
    background-color: rgba(54, 209, 220, 0.1); }
  .dropzone.drag-entered {
    border-color: rgba(81, 216, 225, 0.8);
    background-color: rgba(81, 216, 225, 0.1); }
  .dropzone.is-uploading {
    animation: color-change 2s infinite linear; }

@keyframes color-change {
  0% {
    border-color: #E0E0E0;
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.1); }
  50% {
    border-color: #36D1DC;
    background-color: rgba(54, 209, 220, 0.1);
    color: rgba(0, 0, 0, 0.65); }
  100% {
    border-color: #E0E0E0;
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.1); } }
