$black: #152935;
$white: #FFFFFF;

$black100: #232735;
$black200: #505565;
$black300: #595959;

$grey100: #8B90A0;
$grey200: #D3D4D8;
$grey300: #8C8C8C;

$blue: #36D1DC;
$blue-active: rgba(81, 216, 225, 0.8);
$blue-focus: #51D8E1;

$blue100: #E7F3FC;
$blue600: #399DE5;
$blue700: #3395E2;

$green100: #F2FAE9;
$green600: #96D647;
$green700: #8ED140;

$yellow100: #FFF9E0;
$yellow300: #FFE680;
$yellow600: #FFCC00;

$red100: #FDE7E0;
$red600: #EB3800;
$red700: #E93200;

$red: #EB3800;
$red-active: rgba(235, 56, 0, 0.8);
$red-focus: #EB3800;

$background: #F9FCFF;
$border-color: #E0E0E0;
$border-black: #E1E7ED;

$success-toast-bg: #F2FAE9;
$success-border-close: #96D647;
$error-toast-bg: #FDE7E0;
$error-border-close: #EB3800;
$info-toast-bg: #F9FCFF;
$info-border-close: #399DE5;
