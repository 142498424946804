.card-footer {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%; }
  .card-footer .ant-card-head {
    min-height: 83px; }
    .card-footer .ant-card-head .ant-card-head-title {
      color: #505565;
      font-size: 14px;
      font-family: Sailec, 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 700; }
  .card-footer .text-secondary {
    line-height: 32px; }
  .card-footer .ant-card-body {
    overflow: auto;
    max-height: 230px; }

.truncate {
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.payment-link {
  display: flex; }

.ant-btn.copy-button {
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none; }
  .ant-btn.copy-button:hover {
    cursor: pointer; }
  .ant-btn.copy-button .truncate {
    vertical-align: text-bottom; }

.cancel-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #8B90A0; }
  .cancel-icon-text .title-text {
    font-size: 20px;
    color: #595959;
    font-weight: bold; }
  .cancel-icon-text .subtitle-text {
    margin-bottom: 48px; }
  .cancel-icon-text .cancel-icon {
    margin: 45px 0 32px;
    font-size: 70px;
    color: #399DE5; }
