.webhook-section {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 16px 0;

    span {
      font-weight: bold;
    }
  }
}
