@import "ui/src/styles/colors";
@import "ui/src/styles/fonts";

.ant-row {
  margin: 4px 0;
}

.payment-method-card {
  margin: 12px 4px 32px;

  .payment-method-title {
    font-family: $sailec;
    font-size: 16px;
    font-weight: 700;
    margin: 8px 0;
  }
  .payment-method-actions {
    margin-top: 20px;
  }
}

.payment-method-modal {
  .text-dialog {
    font-family: $sailec;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: $black200;
  }
}