@import '../../styles/card.css';

.new-merchant-project-form .ant-row.ant-form-item.action-buttons .ant-form-item-children {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}


.ant.input.form-input {
  color: #505565;
}

.dropdown-label {
  color: #505565;
  font-size: 16px;
  line-height: 27px;
  padding-bottom: 12px;
}

.button-container {
  margin: 32px 0;
  float: right;

  .add-user-button {
    background-color: #36D1DC;
    border: 0;
    border-radius: 100px;
    font-weight: bold;
    min-height: 48px;
    width: 200px;

    &:hover {
      background-color: #51D8E1;
    }

    &:active {
      background-color: #51D8E1;
      opacity: 0.8;
    }

    &:focus {
      background-color: #51D8E1
    }
  }
}

.-padded-right {
  margin-right: 8px;
}

.ant-card.card-standard.-min-height {
  min-height: initial;
}
